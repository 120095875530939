import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { responseError } from '@/utils/axios/interceptors';

import { Transaction, User } from '@/interfaces';

class ApiFile extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/file'),
          withCredentials: true,
        },
      },
      {
        response: {
          error: [responseError],
        },
      },
    );
  }

  checkTransactionQrcode(formData: FormData) {
    return this.post<{ amount: number, currency: string } | false>('/transaction/check', formData);
  }

  updateTransaction(formData: FormData) {
    return this.patch<Transaction>('/transaction', formData);
  }

  updateAvatar(formData: FormData) {
    return this.patch<User>('/user/avatar', formData);
  }

  deleteAvatar() {
    return this.delete<User>('/user/avatar');
  }
}

export const apiFile = new ApiFile();
