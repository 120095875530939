<script setup lang="ts">
  type NuxtErrorType = {
    statusCode: number
    fatal: boolean
    unhandled: boolean
    statusMessage?: string
    data?: unknown
    cause?: unknown
  }

  interface IErrorPageProps {
    error: NuxtErrorType,
  }

  const props = defineProps<IErrorPageProps>()
  console.error('ErrHandle-FirstErr: ', props.error)

  const { t } = useI18n()

  // const handleError = () => clearError({ redirect: '/catalog' })
  function handleError () {
    clearError()
    location.reload()
  }

  const errorMessage = computed(() => {
    if (props.error.statusCode === 404) {
      return t('pages.error.404')
    } else if (props.error.statusCode === 500) {
      return t('pages.error.500')
    }

    return t('pages.error.unexpectedError')
  })
</script>

<template>
  <div class="error-page">
    <div class="error-page__block">
      <h2 class="error-page__code">{{ error.statusCode }}</h2>

      <div class="error-page__inner-block">
        <h1 class="error-page__message">{{ errorMessage }}</h1>
        <button class="link" @click="handleError">{{ $t('pages.confirm.toCatalog') }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.error-page {
  @apply flex flex-col items-center justify-center;

  &__block {
    @apply flex flex-col md:flex-row
      p-9 md:p-[60px] my-6 rounded-xl
      gap-5 md:gap-10
      items-center justify-center;

    background: var(--ui-color--gradient-long);
  }

  &__inner-block {
    @apply flex flex-col items-start gap-4;
  }

  &__code {
    @apply text-9xl text-accent font-mono font-bold;
  }

  &__message {
    @apply text-xl md:text-2xl;
  }

  .link {
    @apply transition-colors text-accent underline hover:text-accent-hover;
  }
}
</style>