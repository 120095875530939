export default {
  useHead: {
    title: 'Cart',
  },
  cart: 'Cart',
  error: 'An error occurred, the items in the cart may have changed',
  today: 'Today',
  nothingHere: "There's nothing here yet",
  cartIsEmpty: 'Cart is empty',
  clickToReplenish: 'Click on the “Add to Cart” button on the flower card in the Catalog to replenish your Cart.',
  toCatalog: 'To catalog',
  height: 'Height:',
  cm: 'cm',
  price: 'Price:',
  flower: '/flower',
  forAll: 'for everything',
  flowersInStock: 'flowers in stock',
  total: 'Total:',
  inStock: 'In stock:',
  forRegistration: 'For registration',
  delivery: 'Delivery:',
  'delivery-cost-info': 'For order values ​​of 10,000 and above, delivery is free.',
}