export default {
  useHead: {
    placeAnOrder: 'Оформление заказа',
  },
  placeAnOrder: 'Оформление заказа',
  checkTransactionDetails: 'Проверьте детали операции',
  orderList: 'Состав заказа:',
  delivery: 'Доставка',
  pc: 'шт.',
  receiptInformation: 'Информация о получении:',
  pickup: 'Самовывоз:',
  address: 'г. Раменское, ул. Михалевича 151В(для самовывоза позвоните +7(499)288-74-78)',
  totalPayable: 'Итого к оплате:',
  back: 'Назад',
  order: 'Оформить',
  orderIsProcessed: 'Заказ оформлен',
  successfulOperation: 'Успешная операция.',
  paymentCompleted: 'Оплата заказа прошла.',
  toCatalog: 'В каталог',
  historyOfOrders: 'История заказов',
}
