export default {
  title: 'История заказов',
  search: 'Поиск...',
  order: 'Заказ',
  deleteOrder: 'Удалить заказ',
  total: 'Итого:',
  flowers: 'Цветы',
  cancel: 'Отмена',
  apply: 'Применить',
  edit: 'Редактировать',
  useHead: {
    historyTitle: 'История заказов',
    orderTitle: 'Заказ',
  },
  history: {
    height: 'Рост:',
    cm: 'см',
    quantity: 'Количество:',
    pc: 'шт.',
    totalCost: 'Общая стоимость:',
    deliveryFrom: 'Доставка с:',
  },
  edited: {
    height: 'Рост:',
    cm: 'см',
    price: 'Цена:',
    flower: 'цветок',
    forAll: 'за все',
    flowers: 'цветков',
    inStock: 'В наличии:',
  },
  notification: {
    emptyOrder: 'Нельзя сохранить пустой заказ',
    emptyPos: 'Удалите позиции, у которых остался нулевой остаток',
    error: 'Произошла непредвиденная ошибка',
    confirmDel: 'Вы уверены, что хотите удалить этот заказ',
  },
}
