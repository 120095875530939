export function MathCeil10(value: number, exp: number): number {
  return decimalAdjust(value, exp, 'ceil');
}

export function MathRound10(value: number, exp: number): number {
  return decimalAdjust(value, exp, 'round');
}

function decimalAdjust(value: number | string[], exp: number, type: 'round' | 'ceil'): number {
  if (typeof exp === 'undefined' || +exp === 0) return Math[type](+value);
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN;
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

export function stringToDate(_date: any, _format: string, _delimiter: string) {
  var formatLowerCase = _format.toLowerCase();
  var formatItems = formatLowerCase.split(_delimiter);
  var dateItems = _date.split(_delimiter);
  var monthIndex = formatItems.indexOf('mm');
  var dayIndex = formatItems.indexOf('dd');
  var yearIndex = formatItems.indexOf('yyyy');
  var month = parseInt(dateItems[monthIndex]);
  month -= 1;
  var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  return formatedDate;
}

export function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function formatPrice (price: number) {
  let last2Digits = price.toString().slice(-2);

  return parseFloat(price.toString().slice(0, -2) + "." + last2Digits)
    .toFixed(2);
}
