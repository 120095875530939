export default {
  useHead: {
    recoveryTitle: 'Password recovery',
    signinTitle: 'Authorization',
    signupTitle: 'Registration',
  },
  openAuthPageTimer: 'The login screen will open automatically in:',
  newLetter: 'You can request a new letter',
  sec: 'sec.',
  signin: 'Login',
}