import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useModalStore = defineStore('modal', () => {
  const modal = ref<any | null>(null);

  const isPhoneVerifyModal = ref(false);
  const isPhoneVerifed = ref(false);
  const verificationPhone = ref('')
  const phoneCode = ref('RUS');

  const isMobileModal = ref(false);

  const isPictureModal = ref(false);

  const pictureSrc = ref('');

  const getIsMobileModal = computed(() => isMobileModal.value);
  const getIsPictureModal = computed(() => isPictureModal.value);
  const getPictureSrc = computed(() => pictureSrc.value);

  const toggleMobileModal = () => {
    isMobileModal.value = !isMobileModal.value;
  };
  const toggleIsPictureModal = () => {
    isPictureModal.value = !isPictureModal.value;
  };

  const setPictureSrc = (src: string) => {
    pictureSrc.value = src;
  };

  const create = (data: any) => {
    modal.value = data;
  };

  const destroy = () => {
    modal.value = null;
  };

  return {
    modal,
    isMobileModal,
    isPhoneVerifyModal,
    isPhoneVerifed,
    phoneCode,
    pictureSrc,
    getPictureSrc,
    setPictureSrc,
    isPictureModal,
    toggleIsPictureModal,
    getIsPictureModal,
    getIsMobileModal,
    toggleMobileModal,
    create,
    destroy,
    verificationPhone,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalStore, import.meta.hot));
}
