export default {
  useHead: {
    verificationTitle: 'Верификация профиля',
    verificationDesc: 'Верификация профиля',
    profileTitle: 'Профиль',
    profileDesc: 'Профиль',
  },
  applicationBeingReviewed: 'Ваша заявка на рассмотрении. Примерное время ожидания 24 часа.',
  header: {
    profile : 'Профиль',
    otherUsersSee: 'То, как остальные пользователи видят ваш профиль.',
    verification : 'Верификация',
    necessaryData : 'Данные необходимые для осуществления деятельности на бирже.',
  },
  main: {
    role : 'Роль',
    password : 'Пароль',
    changeEmail: 'Сменить почту',
    youWantChangePassword: 'Хотите сменить пароль?',
    changePassword: 'Сменить пароль',
  },
  desc: 'Управляйте настройками своей учетной записи и устанавливайте настройки электронной почты.',
}
