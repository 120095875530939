import { CommonQuery, QueryType, User } from '@/interfaces';
import { apiTable } from '@/services';

export const useOrderStore = defineStore('order', () => {
  const runtimeConfig = useRuntimeConfig();
  const orders = ref<Array<any>>([]);
  const isHasMore = ref(true)

  const query = ref<QueryType & CommonQuery>({
    page: 1,
    limit: 20,
  })

  async function submitOrder(dto: any, user: User) {
    return $fetch(runtimeConfig.public.apiUrl + '/api/purchase/create', {
      method: 'POST',
      body: { purchase: dto, user },
    });
  }

  async function fetchOrders () {
    return apiTable.getPagination('/purchase/list', query.value)
      .then(({ data }) => {
        if (query.value.page === 1) {
          orders.value = data;
        } else {
          orders.value = orders.value.concat(data)
        }

        isHasMore.value = !!data.length
      })
      .catch(() => {
        useNotificationStore().create({
          type: 'error',
          message: 'Что-то пошло не так',
        })
      })
  }

  return {
    orders,
    isHasMore,
    query,
    submitOrder,
    fetchOrders,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrderStore, import.meta.hot));
}
