import cardEn from './card/en';
import filterEn from './filter/en';
import footerEn from './footer/en';
import headerEn from './header/en';
import paginationEn from './pagination/en';
import sortEn from './sort/en';

export default {
  card: cardEn,
  filter: filterEn,
  footer: footerEn,
  header: headerEn,
  pagination: paginationEn,
  sort: sortEn,
  container: {
    banner: {
      title: 'Fresh flowers for you',
      text: {
        0: "We focus on quality, that's why for us",
        1: 'it is extremely important to keep flowers in our warehouse',
        2: 'in supportive, comfortable conditions.'
      },
    },
  },
}
