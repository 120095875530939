import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { requestSetAuthHeader, responseError } from '@/utils/axios/interceptors';

import type { CommonObject, CommonQuery, Plantation } from '@/interfaces';

class ApiPlantation extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/plantation'),
          withCredentials: true,
        },
      },
      {
        request: {
          success: [requestSetAuthHeader],
        },
        response: {
          error: [responseError],
        },
      },
    );
  }

  getAll(params?: Record<string, any>) {
    return this.get<Plantation[]>('/all', { params });
  }

  getList(params: CommonQuery) {
    return this.get<Plantation[]>('/list', { params });
  }

  getItem(_id: string) {
    return this.get<Plantation>(`/${_id}`);
  }

  getCountryList() {
    return this.get<CommonObject[]>('/country/list');
  }

  getCityList(context: string) {
    return this.get<CommonObject[]>('/city/list', { params: { context } });
  }
}

export const apiPlantation = new ApiPlantation();
