export default {
  recovery: {
    title: 'Password recovery',
    description: 'Enter the email you provided during registration. A letter with instructions will be sent to him.',
  },
  'recovery-email': {
    title: 'Check your email',
    description: 'A letter with instructions for password recovery has been sent to the specified E-mail.',
  },
  'recovery-verify': {
    title: 'Password recovery',
    description: 'Create a new password.',
  },
  'recovery-confirmed': {
    title: 'Password updated successfully',
    description: 'Login to the platform using your login details.',
  },
  'recovery-expired': {
    title: 'Link has expired',
    description: 'You cannot verify your email using this link.',
  },
  signin: {
    title: 'Account Login',
    description: 'Enter your E-mail and password.',
  },
  signup: {
    title: 'Create an account',
    description: 'Fill out the fields to create an account.',
  },
  'signup-email': {
    title: 'Check your email',
    description: 'Confirm your email by following the link in the letter.',
  },
  'signup-verify': {
    title: 'Email successfully confirmed',
    description: 'Login to the platform using your login details.',
  },
  'signup-expired': {
    title: 'Link has expired',
    description: 'You cannot verify your email using this link.',
  },
}