export default {
  useHead: {
    recoveryTitle: 'Восстановление пароля',
    signinTitle: 'Авторизация',
    signupTitle: 'Регистрация',
  },
  openAuthPageTimer: 'Экран авторизации откроется автоматически через:',
  newLetter: 'Вы можете запросить новое письмо',
  sec: 'сек.',
  signin: 'Войти',
}