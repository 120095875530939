import { apiPlantation } from '@/services';
import { CommonObject } from '@/interfaces';
import { generateDropEnum } from '@/components/common/modal/initial/requests';

export const useGlobalStore = defineStore('global', () => {
  const enums = ref<any>()
  const countries = ref<CommonObject[]>([])

  const is = reactive({
    pending: false,
  });

  const setIsPending = (value: boolean) => (is.pending = value)
  const setCountries = (value: CommonObject[]) => {
    enums.value = generateDropEnum(value)
    countries.value = value
  }

  const getters = {
    getIsPending: computed(() => is.pending),
    getCountries: computed(() => countries.value),
    getCountriesEnum: computed(() => enums.value),
  }

  const loadCountryList = async () => {
    if (countries.value.length) return;

    apiPlantation.getCountryList()
      .then(({ data }) => {
        setCountries(data);
      })
      .catch(err => {
        console.error(err)
      })
  }

  return {
    ...getters,

    loadCountryList,
    setIsPending,
    setCountries,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot));
}
