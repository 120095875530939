export default {
  recipient: 'Получатель',
  selectRecipient: 'Выберите получателя',
  plantation: 'Плантация',
  selectPlantation: 'Выберите плантацию',
  enterPlantationName: 'Введите название плантации',
  accounts: '# Счета',
  enterAccountNumber: 'Введите номер счета',
  city: 'Город',
  enterCity: 'Введите город',
  postalCode: 'Почтовый индекс',
  enterPostcode: 'Введите почтовый индекс',
  bankCountry: 'Страна банка',
  selectBankCountry: 'Выберите страну банка',
  enterRoutingNumber: 'Введите Routing Number',
  SWIFTBankCode: 'SWIFT код банка',
  enterCode: 'Введите код',
  selectAccount: 'Выберите счёт',
  debitFromAccount: 'Списать со счёта',
  amount: 'Сумма',
  enterAmount: 'Введите сумму',
}
