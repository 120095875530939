export default {
  Russia: 'Россия',
  USA: 'США',
  Canada: 'Канада',
  Belarus: 'Белорусь',
  Kazakhstan: 'Казахстан',
  Uzbekistan: 'Узбекистан',
  Türkiye: 'Турция',
  Thailand: 'Тайланд',
  Tajikistan: 'Таджикистан',
  Slovakia: 'Словакия',
  Slovenia: 'Словения',
  Singapore: 'Сингапур',
  SaudiArabia: 'Саудовская Аравия',
  Romania: 'Румыния',
  SouthKorea: 'Южная Корея',
  Poland: 'Польша',
  UAE: 'ОАЭ',
  Norway: 'Норвегия',
  Netherlands: 'Нидерланды',
  Mongolia: 'Монголия',
  Lithuania: 'Литва',
  Latvia: 'Латвия',
  China: 'Китай',
  Kyrgyzstan: 'Киргизия',
  Spain: 'Испания',
  Italy: 'Италия',
  Indonesia: 'Индонезия',
  India: 'Индия',
  Israel: 'Израиль',
  Egypt: 'Египет',
  Denmark: 'Дания',
  Georgia: 'Грузия',
  Greece: 'Греция',
  Germany: 'Германия',
  Vietnam: 'Вьетнам',
  GreatBritain: 'Великобритания',
  Brazil: 'Бразилия',
  Bulgaria: 'Болгария',
  Belgium: 'Бельгия',
  Armenia: 'Армения',
  Azerbaijan: 'Азербайджан',
  Australia: 'Австралия',
  SouthAfrica: 'ЮАР',
  Estonia: 'Эстония',
  Sweden: 'Швеция',
  Switzerland: 'Швейцария',
  Czech: 'Чехия',
  Montenegro: 'Черногория',
  Croatia: 'Хорватия',
  France: 'Франция',
  Finland: 'Финляндия',
  Philippines: 'Филиппины',
  Turkmenistan: 'Туркменистан',
  Portugal: 'Португалия',
  NewZealand: 'Новая Зеландия',
  Ukraine: 'Украина',
  Japan: 'Япония',
};
