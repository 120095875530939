import { Wallet } from '@/interfaces';
import { defineStore } from 'pinia';

export const useWalletStore = defineStore('wallet', () => {
  const wallet = ref<Wallet | null>(null);

  const getters = {
    getWallet: computed(() => wallet.value),
  };

  const create = (value: Wallet) => (wallet.value = value);

  const destroy = () => {
    wallet.value = null;
  }

  return {
    ...getters,

    create,
    destroy,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWalletStore, import.meta.hot));
}
