import { CommonQuery, QueryType } from '@/interfaces';
import { apiTable } from '@/services';

export const useOperationsHistoryStore = defineStore('operations-history', () => {
  const history = ref<Array<any>>([])
  // const history = ref<Array<any>>([
  //   { operationIntId: '123456789', amount: '123456789', createdAt: '2024-02-23T13:55:47.036Z', appointment: 'Пополнение', status: 'В процессе' },
  //   { operationIntId: '12345678', amount: '123456789', createdAt: '2024-02-23T13:55:47.036Z', appointment: 'Покупка цветов', status: 'В процессе' },
  //   { operationIntId: '1234567', amount: '123456789', createdAt: '2024-02-23T13:55:47.036Z', appointment: 'Пополнение', status: 'Исполнен' },
  //   { operationIntId: '123456', amount: '123456789', createdAt: '2024-02-23T13:55:47.036Z', appointment: 'Пополнение', status: 'Отменен' },
  //   { operationIntId: '12345', amount: '123456789', createdAt: '2024-02-23T13:55:47.036Z', appointment: 'Пополнение', status: 'В процессе' },
  //   { operationIntId: '1234', amount: '123456789', createdAt: '2024-02-23T13:55:47.036Z', appointment: 'Покупка цветов', status: 'Исполнен' },
  //   { operationIntId: '123', amount: '123456789', createdAt: '2024-02-23T13:55:47.036Z', appointment: 'Пополнение', status: 'В процессе' },
  //   { operationIntId: '1231ыапывапр', amount: '123456789', createdAt: '2024-02-23T13:55:47.036Z', appointment: 'Покупка цветов', status: 'В процессе' },
  // ])

  const isHasMore = ref(true)

  const query = ref<QueryType & CommonQuery>({
    page: 1,
    limit: 20,
  })

  async function fetchHistory () {
    return apiTable.getPagination('/operations-history/list', query.value)
      .then(({ data }) => {
        if (query.value.page === 1) {
          history.value = data;
        } else {
          history.value = history.value.concat(data)
        }

        isHasMore.value = !!data.length
      })
      .catch(() => {
        useNotificationStore().create({
          type: 'error',
          message: 'Что-то пошло не так',
        })
      })
  }

  return {
    history,
    isHasMore,
    query,
    fetchHistory,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOperationsHistoryStore, import.meta.hot));
}
