import AxiosWrapper, { ApiMethodParams } from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { responseError } from '@/utils/axios/interceptors';

class ApiModal extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl(''),
          withCredentials: true,
        },
      },
      {
        response: {
          error: [responseError],
        },
      },
    );
  }

  async submit(config: ApiMethodParams) {
    return this.request(config);
  }
}

export const apiModal = new ApiModal();
