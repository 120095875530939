export default {
  notChoosen: 'The quantity of the desired product has not been selected',
  exceeds: 'The selected quantity exceeds availability',
  added: 'Item added to cart!',
  cm: 'cm',
  height: 'Height',
  price: 'Price',
  availability: 'Availability',
  pc: 'pc',
  inTheBasket: 'In the basket',
  addToCart: 'Add to cart',
}