import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { responseError } from '@/utils/axios/interceptors';

import { Plantation } from '@/interfaces';

class ApiRecipient extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/recipient'),
          withCredentials: true,
        },
      },
      {
        response: {
          error: [responseError],
        },
      },
    );
  }

  create(data: any) {
    return this.post<Plantation>('', data);
  }
}

export const apiRecipient = new ApiRecipient();
