import authEn from './auth/en';
import catalogEn from './catalog/en';
import commonEn from './common/en';
import recipientEn from './recipient/en';
import assortmentEn from './assortment/en';

export default {
  auth: authEn,
  catalog: catalogEn,
  common: commonEn,
  recipient: recipientEn,
  assortment: assortmentEn,
}