export default {
  useHead: {
    transfers: 'Transfers', // order
    transferBetweenAccounts: 'Перевод между счетами',
  },
  transfers: 'Transfers', // order
  recipient: 'Recipient',
  sum: 'Sum',
  review: 'Review',
  notEnoughFunds: 'There are not enough funds in your account!',
  betweenAccounts: 'Between accounts',
  back: 'Back',
  ordering: 'Ordering',
  confirm: 'Confirm',
  continue: 'Continue',
}
