import headerEn from './header/en';
import inputsEn from './inputs/en';
import notificationEn from './notification/en';
import phoneEn from './phone/en';
import qrcodeEn from './qrcode/en';
import tableEn from './table/en';
import walletEn from './wallet/en';

export default {
  processing: 'Processing...',
  youReallyWant: 'Do you really want',
  loading: 'Loading...',
  no: 'No',
  yes: 'Yes',
  add: 'Add',
  balance: {
    title: 'Replenishment of balance',
    description: 'Instenctions for use.',
    submitName: 'Replenish',
    rejectInput: 'Upload the QR code!',
    enterAmount: 'Enter the amount.',
  },
  order: {
    reqForTrans: 'Request for translation',
    enterAmount: 'Enter the amount to transfer.',
    pay: 'Pay',
    confirmOfReceipt: 'Confirmation of receipt',
    plantationReceivedFunds: 'Do you really want to confirm that the plantation has received funds?',
  },
  recipient: {
    addingRecipient: 'Adding recipient',
    enterPlantation: 'Enter plantation details to transfer funds.',
  },
  errors: {
    requiredAll: 'Fill in all required fields!',
    nothingFound: 'Nothing found',
  },
  recipients: {
    requisites: 'Requisites',
    verificationDetails: 'Requisites are being verified!',
    noPropsToChoose: 'There are no props to choose from!',
  },
  search: {
    placeholder: 'Start typing the name of the flower',
    default: 'Search...',
  },
  status: {
    new: 'New',
    confirmed: 'Confirmed',
    assembled: 'Assembled',
    shipped: 'Shipped',
    delivered: 'Delivered',
    return: 'Return',
    canceled: 'Canceled',
    partiallyShipped: 'Partially shipped',
    inProcess: 'In process',
    fulfilled: 'Fulfilled',
  },
  header: headerEn,
  inputs: inputsEn,
  notification: notificationEn,
  phone: phoneEn,
  qrcode: qrcodeEn,
  table: tableEn,
  wallet: walletEn,
}
