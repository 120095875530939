import headerRu from './header/ru';
import inputsRu from './inputs/ru';
import notificationRu from './notification/ru';
import phoneRu from './phone/ru';
import qrcodeRu from './qrcode/ru';
import tableRu from './table/ru';
import walletRu from './wallet/ru';

export default {
  processing: 'Обработка...',
  youReallyWant: 'Вы действительно хотите',
  loading: 'Загрузка...',
  no: 'Нет',
  yes: 'Да',
  add: 'Добавить',
  balance :{
    title: 'Пополнение баланса',
    description: 'Инструкция пользования.',
    submitName: 'Пополнить',
    rejectInput: 'Загрузите QR код!',
    enterAmount: 'Введите сумму.',
  },
  order: {
    reqForTrans: 'Заявка на перевод',
    enterAmount: 'Введите сумму для перевода.',
    pay: 'Оплатить',
    confirmOfReceipt: 'Подтверждение получения',
    plantationReceivedFunds: 'Вы действительно хотите подтвердить факт получения денежных средств плантацией?',
  },
  recipient: {
    addingRecipient: 'Добавление получателя',
    enterPlantation: 'Введите данные плантации для осуществления перевода средств.',
  },
  errors: {
    requiredAll: 'Заполните все обязательные поля!',
    nothingFound: 'Ничего не найдено',
  },
  recipients: {
    requisites: 'Реквизиты',
    onVerification: 'Реквизиты на верификации',
    noPropsToChoose: 'Нет ни одного реквизита для выбора!',
  },
  search: {
    placeholder: 'Начните вводить название цветка',
    default: 'Поиск...'
  },
  status: {
    new: 'Новый',
    inProcess: 'В процессе',
    confirmed: 'Подтвержден',
    assembled: 'Собран',
    shipped: 'Отгружен',
    partiallyShipped: 'Частично отгружен',
    delivered: 'Доставлен',
    fulfilled: 'Исполнен',
    return: 'Возврат',
    canceled: 'Отменен',
  },
  header: headerRu,
  inputs: inputsRu,
  notification: notificationRu,
  phone: phoneRu,
  qrcode: qrcodeRu,
  table: tableRu,
  wallet: walletRu,
}
