import containerRu from './container/ru';

export default {
  filters: 'Фильтры',
  resetFilters: 'Сбросить фильтры',
  addFilters: 'Добавить фильтры',
  apply: 'Применить',
  allTime: 'За все время',
  cancel: 'Отменить',
  save: 'Сохранить',
  container: containerRu,
}
