import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { responseError } from '@/utils/axios/interceptors';

import { User, UserVerification } from '@/interfaces';

class ApiUser extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/user'),
          withCredentials: true,
        },
      },
      {
        response: {
          error: [responseError],
        },
      },
    );
  }

  verification(data: UserVerification) {
    return this.patch<User>('/verification', data);
  }
}

export const apiUser = new ApiUser();
