export default {
  notChoosen: 'Не выбрано количество желаемого товара',
  exceeds: 'Выбранное количество превышает наличие',
  added: 'Товар добавлен в корзину!',
  cm: 'cм',
  height: 'Рост',
  price: 'Цена',
  availability: 'Наличие',
  pc: 'шт.',
  inTheBasket: 'В корзине',
  addToCart: 'В корзину',
}