export default {
  recognition: 'Распознаю QR-код...',
  notRecognized: 'QR-код не распознан',
  uploadQR: 'Загрузить QR-код',
  codeUploaded: 'QR-код загружен',
  instruction: {
    1: 'Зайдите в приложение Тинькофф',
    2: 'Нажмите «Платежи» → «Действия» → «Снять наличные»',
    3: 'Выберите счет и введите сумму, которую которую хотите внести на баланс',
    4: 'Нажмите «Получить QR-код»',
    5: 'Сохраните на телефон полученный QR-код',
    6: 'Загрузите полученный QR-код в систему по кнопке "Загрузить QR-код"',
  },
}
