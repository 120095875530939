import backgroundRu from './background/ru';
import headerRu from './header/ru';
import infoRu from './info/ru';
import routingRu from './routing/ru';

export default {
  background: backgroundRu,
  header: headerRu,
  info: infoRu,
  routing: routingRu,
}