<script setup lang="ts">
import { useModalStore } from '@/stores/modal';
import { useWalletStore } from './stores';

import Preloader from '@page/preloader/index.vue';

const loading = ref(true);
const nuxtApp = useNuxtApp();
const modalStore = useModalStore();
const walletStore = useWalletStore();

nuxtApp.hook('page:start', () => {
  loading.value = true;
});
nuxtApp.hook('page:finish', () => {
  loading.value = false;
});

useHead({
  titleTemplate: (title) => `${title} | IFC Trading Group`,
});
</script>

<template>
  <NuxtLayout>
    <transition name="fade">
      <preloader v-if="loading"></preloader>
    </transition>
    <NuxtPage></NuxtPage>
    <transition name="fade">
      <lazy-common-modal v-if="modalStore.modal"></lazy-common-modal>
    </transition>
    <transition name="fade">
      <lazy-common-wallet v-if="walletStore.getWallet"></lazy-common-wallet>
    </transition>
    <lazy-common-notification></lazy-common-notification>
  </NuxtLayout>
</template>

<style scoped lang="scss"></style>
