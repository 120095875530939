import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { responseError } from '@/utils/axios/interceptors';

import { CommonQuery, CreateMyselfOrderDto, CreateOrderDto, Order } from '@/interfaces';

class ApiOrder extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/order'),
          withCredentials: true,
        },
      },
      {
        response: {
          error: [responseError],
        },
      },
    );
  }

  getList(params: CommonQuery) {
    return this.get<Order[]>('/list', { params });
  }

  create(data: CreateOrderDto) {
    return this.post<Order | { value: number; course: number }>('/garantex', data);
  }

  createMyself(data: CreateMyselfOrderDto) {
    return this.post<Order | { value: number; course: number }>('/myself', data);
  }
}

export const apiOrder = new ApiOrder();
