import { apiPlantation } from '@/services';
import { usePlantationModel } from '@/models';
import type { InfinityScrollLoad, Plantation, PlantationModel } from '@/interfaces';

export const usePlantationStore = defineStore('plantation', () => {
  const list = ref<PlantationModel[]>([]);
  const query = reactive({
    page: 1,
    limit: 28,
    search: '',
  });
  const is = reactive({
    init: false,
    loaded: false,
    pending: false,
  });

  const getters = {
    getList: computed(() => list.value),
    getIsInit: computed(() => is.init),
    getIsLoad: computed(() => is.loaded),
    getQuerySearch: computed(() => query.search),
  };

  const setQuerySearch = (value: string) => {
    reset();
    query.search = value;
    loadList();
  };
  const setIsPending = (value: boolean) => (is.pending = value);
  const setIsLoaded = (value: boolean) => (is.loaded = value);
  const setIsInit = (value: boolean) => (is.init = value);
  const setItem = (value: Plantation) => {
    list.value.push(usePlantationModel(value));
  };

  const updateItem = (value: Plantation) => {
    const item = list.value.find((i) => i.getId === value._id);
    if (item) updateObject(item, usePlantationModel(value));
  };

  const loadList = async ($inf?: InfinityScrollLoad) => {
    if (is.loaded || is.pending) return
    setIsPending(true)

    apiPlantation.getList(query)
      .then(({ data }) => {
        for (const item of data) setItem(item);

        query.page++;
        setIsInit(true);
        setIsLoaded(data.length !== query.limit);
        if ($inf) $inf.done(data.length !== query.limit);
      })
      .finally(() => {
        setIsPending(false);
      })
  }

  const reset = () => {
    list.value = [];
    query.page = 1;
    query.limit = 24;
    query.search = '';
    is.init = false;
    is.loaded = false;
    is.pending = false;
  };

  return {
    list,
    query,
    is,
    ...getters,

    reset,
    setItem,
    loadList,
    updateItem,
    setQuerySearch,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlantationStore, import.meta.hot));
}
