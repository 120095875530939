export default {
  copyAddress: 'Адрес кошелька скопирован в буфер обмена!',
  address: 'Адрес',
  warning: {
    1: 'Отправляйте на этот адрес только USDT.',
    2: 'Убедитесь, что сеть верна: Tron (TRC20).',
    3: 'Не отправляйте NFT на этот адрес.',
  },
  description: 'Отсканируйте код на странице вывода в приложении торговой платформы или кошелька. После оплаты нажмите кнопку “Оплачено”.',
  amoutn: 'Сумма пополнения:',
  time: 'Время на оплату:',
  confirmDestroy: 'При закрытии этого окна вы не сможете подтвердить платеж',
  timeIsUp: 'Время на оплату вышло! Создайте новый платеж или обратитесь в службу поддержки!',
  success: 'Операция успешно подтверждена!',
  confirmExit: 'Вы уверены, что хотите покинуть страницу?',
  paid: 'Оплачено',
  topUp: 'Пополнить счёт',
}