import backgroundEn from './background/en';
import headerEn from './header/en';
import infoEn from './info/en';
import routingEn from './routing/en';

export default {
  background: backgroundEn,
  header: headerEn,
  info: infoEn,
  routing: routingEn,
}