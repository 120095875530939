
export default {
  recovery: {
    title: 'Зарегистрироваться',
  },
  'recovery-email': {
    title: 'Зарегистрироваться',
  },
  'recovery-verify': {
    title: 'Зарегистрироваться',
  },
  'recovery-confirmed': {
    title: 'Зарегистрироваться',
  },
  'recovery-expired': {
    title: 'Зарегистрироваться',
  },
  signin: {
    title: 'Зарегистрироваться',
  },
  signup: {
    title: 'Войти',
  },
  'signup-email': {
    title: 'Войти',
  },
  'signup-verify': {
    title: 'Войти',
  },
  'signup-expired': {
    title: 'Войти',
  },
}