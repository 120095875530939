export default {
  recovery: {
    title: 'Восстановление пароля',
    description: 'Введите E-mail, который указывали при регистрации. На него будет отправлено письмо с инструкцией.',
  },
  'recovery-email': {
    title: 'Проверьте почту',
    description: 'На указанный E-mail выслано письмо с инструкцией по восстановлению пароля.',
  },
  'recovery-verify': {
    title: 'Восстановление пароля',
    description: 'Придумайте новый пароль.',
  },
  'recovery-confirmed': {
    title: 'Пароль успешно обновлён',
    description: 'Войдите на платформу используя свои данные для входа.',
  },
  'recovery-expired': {
    title: 'Срок действия ссылки истёк',
    description: 'Вы не можете подтвердить почту по данной ссылке.',
  },
  signin: {
    title: 'Вход в аккаунт',
    description: 'Введите ваш E-mail и пароль.',
  },
  signup: {
    title: 'Создайте аккаунт',
    description: 'Заполните поля, чтобы создать аккаунт.',
  },
  'signup-email': {
    title: 'Проверьте почту',
    description: 'Подтвердите вашу почту, перейдя по ссылке в письме.',
  },
  'signup-verify': {
    title: 'Почта успешно подтверждена',
    description: 'Войдите на платформу используя свои данные для входа.',
  },
  'signup-expired': {
    title: 'Срок действия ссылки истёк',
    description: 'Вы не можете подтвердить почту по данной ссылке.',
  },
}