import cardRu from './card/ru';
import filterRu from './filter/ru';
import footerRu from './footer/ru';
import headerRu from './header/ru';
import paginationRu from './pagination/ru';
import sortRu from './sort/ru';

export default {
  card: cardRu,
  filter: filterRu,
  footer: footerRu,
  header: headerRu,
  pagination: paginationRu,
  sort: sortRu,
  container: {
    banner: {
      title: 'Свежие цветы для Вас',
      text: {
        0: 'Мы делаем ставку на качество, поэтому для нас',
        1: 'крайне важно содержать цветы на нашем складе',
        2: 'в поддерживающих комфортных условиях.'
      },
    },
  },
}
