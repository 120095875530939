import { ModalMain, StructureInput } from '@/interfaces';
import { apiPlantation } from '@/services';

export const generateDropEnum = (array: Array<any>) => {
  return array.reduce((acc: Record<string, any>, el) => {
    acc[el._id] = el.name;
    return acc;
  }, {});
};

export const modalRequests = (modal: ModalMain): void => {
  const isRecipient = modal.content.id === 'recipient-create';

  const inputs = modal.inputs?.inputs as StructureInput[];
  // const data = modal.inputs?.data as Record<string, any>;
  if (isRecipient) {
    getPlantationAll(inputs);
    getGeoCountryList(inputs);
  }
};

export const getPlantationAll = (inputs: StructureInput[]) => {
  return apiPlantation.getAll()
    .then(({ data: list }) => {
      const input = inputs.find((i) => i.id === 'plantation')
      if (!input) return

      input.drop = list
      input.drop_context = list
      input.drop_enum = generateDropEnum(list)
    })
}

export const getGeoCountryList = async (inputs: StructureInput[]) => {
  const global = useGlobalStore();
  const list = global.getCountries;
  const array = inputs.filter((i) => i.id.includes('ountry'));
  for (const input of array) {
    input.drop = list;
    input.drop_context = list;
    input.drop_enum = generateDropEnum(list);
  }
};
