import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { requestSetAuthHeader, responseError } from '@/utils/axios/interceptors';

import type {
  AuthRecoveryDto,
  AuthRecoveryVerifyDto,
  AuthSigninDto,
  AuthSignupDto,
  AuthSignupVerifyDto,
  User,
} from '@/interfaces';

class ApiAuth extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl('/auth'),
          withCredentials: true,
        },
      },
      {
        request: {
          success: [requestSetAuthHeader],
        },
        response: {
          error: [responseError],
        },
      },
    );
  }

  authentication() {
    return this.get<User>('/authentication');
  }

  signin(data: AuthSigninDto) {
    return this.post<User>('/signin', data);
  }

  signup(data: AuthSignupDto) {
    return this.post('/signup', data);
  }

  signupVerify(data: AuthSignupVerifyDto) {
    return this.post('/signup/verify', data);
  }

  recovery(data: AuthRecoveryDto) {
    return this.post<User>('/recovery', data);
  }

  recoveryVerify(data: AuthRecoveryVerifyDto) {
    return this.post<User>('/recovery/verify', data);
  }

  logout() {
    return this.post('/logout');
  }
}

export const apiAuth = new ApiAuth();
