import { AxiosError } from 'axios';

export const responseError = async (error: AxiosError<any>) => {
  const notification = useNotificationStore();

  const res = error.response;
  if (!res) return Promise.reject(error);

  if (res.data.message) notification.create({ message: res.data.message, type: 'error' });

  return Promise.reject(error);
};
