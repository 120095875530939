export default {
  Russia: 'Russia',
  USA: 'USA',
  Canada: 'Canada',
  Belarus: 'Belarus',
  Kazakhstan: 'Kazakhstan',
  Uzbekistan: 'Uzbekistan',
  Türkiye: 'Türkiye',
  Thailand: 'Thailand',
  Tajikistan: 'Tajikistan',
  Slovakia: 'Slovakia',
  Slovenia: 'Slovenia',
  Singapore: 'Singapore',
  SaudiArabia: 'Saudi Arabia',
  Romania: 'Romania',
  SouthKorea: 'South Korea',
  Poland: 'Poland',
  UAE: 'UAE',
  Norway: 'Norway',
  Netherlands: 'Netherlands',
  Mongolia: 'Mongolia',
  Lithuania: 'Lithuania',
  Latvia: 'Latvia',
  China: 'China',
  Kyrgyzstan: 'Kyrgyzstan',
  Spain: 'Spain',
  Italy: 'Italy',
  Indonesia: 'Indonesia',
  India: 'India',
  Israel: 'Israel',
  Egypt: 'Egypt',
  Denmark: 'Denmark',
  Georgia: 'Georgia',
  Greece: 'Greece',
  Germany: 'Germany',
  Vietnam: 'Vietnam',
  GreatBritain: 'Great Britain',
  Brazil: 'Brazil',
  Bulgaria: 'Bulgaria',
  Belgium: 'Belgium',
  Armenia: 'Armenia',
  Azerbaijan: 'Azerbaijan',
  Australia: 'Australia',
  SouthAfrica: 'South Africa',
  Estonia: 'Estonia',
  Sweden: 'Sweden',
  Switzerland: 'Switzerland',
  Czech: 'Czech',
  Montenegro: 'Montenegro',
  Croatia: 'Croatia',
  France: 'France',
  Finland: 'Finland',
  Philippines: 'Philippines',
  Turkmenistan: 'Turkmenistan',
  Portugal: 'Portugal',
  NewZealand: 'New Zealand',
  Ukraine: 'Ukraine',
  Japan: 'Japan',
};
