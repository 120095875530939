export default {
  recognition: 'QR code recognition...',
  notRecognized: 'QR code not recognized',
  uploadQR: 'Upload QR code',
  codeUploaded: 'QR code uploaded',
  instruction: {
    1: 'Go to the Tinkoff application',
    2: 'Click Payments → Actions → Withdraw Cash',
    3: 'Select an account and enter the amount you want to deposit',
    4: 'Click "Get QR code"',
    5: 'Save the received QR code to your phone',
    6: 'Upload the received QR code to the system using the "Upload QR code" button',
  },
}
