export default {
  copyAddress: 'The wallet address has been copied to the clipboard!',
  address: 'Address',
  warning: {
    1: 'Only send USDT to this address.',
    2: 'Make sure the network is correct: Tron (TRC20).',
    3: 'Do not send NFTs to this address.',
  },
  description: 'Scan the code on the withdrawal page in the trading platform or wallet app. After payment, click the “Paid” button.',
  amount: 'Top-up amount:',
  time: 'Time to pay:',
  confirmDestroy: 'If you close this window, you will not be able to confirm the payment',
  timeIsUp: 'Time to pay is up! Create a new payment or contact support!',
  success: 'Operation successfully confirmed!',
  confirmExit: 'Are you sure you want to leave the page?',
  paid: 'Paid',
  topUp: 'Top up your account',
}