export default {
  useHead: {
    verificationTitle: 'Profile verification',
    verificationDesc: 'Profile verification',
    profileTitle: 'Profile',
    profileDesc: 'Profile',
  },
  applicationBeingReviewed: 'Your application is being reviewed. Approximate waiting time 24 hours.',
  header: {
    profile : 'Profile',
    otherUsersSee: 'The way other users see your profile.',
    verification : 'Verification',
    necessaryData : 'Data necessary for carrying out activities on the exchange.',
  },
  main: {
    role : 'Role',
    password : 'Password',
    changeEmail: 'Change email',
    youWantChangePassword: 'Do you want to change your password?',
    changePassword: 'Change password',
  },
  desc: 'Manage your account settings and set your email preferences.',
}
