import componentsEn from './components/en';
import dictionariesEn from './dictionaries/en';

import authEn from './pages/auth/en';
import cartEn from './pages/cart/en';
import errorEn from './pages/error/en';
import catalogEn from './pages/catalog/en';
import confirmEn from './pages/confirm/en';
import historyEn from './pages/history/en';
import operationsHistoryEn from './pages/operations-history/en';
import orderEn from './pages/order/en';
import profileEn from './pages/profile/en';


export default {
  components: componentsEn,
  dictionaries: dictionariesEn,
  pages: {
    auth: authEn,
    cart: cartEn,
    catalog: catalogEn,
    error: errorEn,
    confirm: confirmEn,
    history: historyEn,
    operationsHistory: operationsHistoryEn,
    order: orderEn,
    profile: profileEn,
  },
};
