import AxiosWrapper from '@/utils/axios/wrapper';
import { getApiUrl } from '../url';
import { requestSetAuthHeader, responseError } from '@/utils/axios/interceptors';

import type { CommonQuery } from '@/interfaces';

class ApiTable extends AxiosWrapper {
  constructor() {
    super(
      {
        config: {
          baseURL: getApiUrl(''),
          withCredentials: true,
        },
      },
      {
        request: {
          success: [requestSetAuthHeader],
        },
        response: {
          error: [responseError],
        },
      },
    );
  }

  getPagination(url: string, params: CommonQuery) {
    return this.request<Record<string, any>[]>({ url, method: 'get', requestConfig: { params } });
  }

  getFilterList(url: string) {
    return this.get<Record<string, any>[]>('/list', { url, method: 'get' });
  }
}

export const apiTable = new ApiTable();
