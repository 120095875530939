import { CheckboxFilterType, StringKeyType } from '@/interfaces';

export interface IFiltersResponsee {
  dates: string[];
  plantations: string[];
  types: string[];
  name: string[];
  lengths: string[];
}

export const useAssortmentStore = defineStore('assortment', () => {
  const submittedArray = ref<Array<any>>([]);

  const typeFilter = ref<CheckboxFilterType[]>([]);
  const nameFilter = ref<CheckboxFilterType[]>([]);
  const plantationFilter = ref<CheckboxFilterType[]>([]);
  const lengthFilter = ref<CheckboxFilterType[]>([]);
  const fromPrice = ref<number | null>(null);
  const toPrice = ref<number | null>(null);
  const dateFilter = ref<Array<any>>([]);
  const dates = ref<string[]>([]);

  const { t } = useI18n();

  const typeFilterTranslateDictionaries = computed<StringKeyType>(() => ({
    'Альстромерия/Эквадор': t('dictionaries.typeFilter.alstromeria'),
    Гипсофила: t('dictionaries.typeFilter.gypsophila'),
    Красители: t('dictionaries.typeFilter.dyes'),
    'Кустовые розы/Эквадор': t('dictionaries.typeFilter.sprayRoses'),
    'Розы/Эквадор': t('dictionaries.typeFilter.roses'),
    Хризантемы: t('dictionaries.typeFilter.chrysanthemum'),
    'Гвоздика/Китай': t('dictionaries.typeFilter.gvozdika'),
    'Эвкалипт/Китай': t('dictionaries.typeFilter.evkalipt'),
    'Гиперикум/Китай': t('dictionaries.typeFilter.giperikum'),
    Писташ: t('dictionaries.typeFilter.pistash'),
  }));

  const runtimeConfig = useRuntimeConfig();

  async function fetchFilters() {
    return await $fetch<{ data: IFiltersResponsee }>(
      runtimeConfig.public.apiUrl + '/api/moysklad/filterList',
    );
  }

  function toCheckboxFilterObjs(arr: Array<any>) {
    return arr.map((item) => ({
      text: item,
      checked: false,
    }));
  }

  function setFilters(payload: IFiltersResponsee) {
    dates.value = payload.dates;

    plantationFilter.value = toCheckboxFilterObjs(payload.plantations);
    typeFilter.value = toCheckboxFilterObjs(payload.types);
    nameFilter.value = toCheckboxFilterObjs(payload.name);
    lengthFilter.value = toCheckboxFilterObjs(payload.lengths);
  }

  function resetPlantationFilter() {
    plantationFilter.value = plantationFilter.value.map((item: any) => {
      return { ...item, checked: false };
    });
  }
  function resetTypeFilter() {
    typeFilter.value = typeFilter.value.map((item: any) => {
      return { ...item, checked: false };
    });
  }
  function resetNameFilter() {
    nameFilter.value = nameFilter.value.map((item: any) => {
      return { ...item, checked: false };
    });
  }

  function resetAll() {
    resetPlantationFilter();
    resetTypeFilter();
    resetNameFilter();
    lengthFilter.value = lengthFilter.value.map((item: any) => {
      return { ...item, checked: false };
    });
    fromPrice.value = null;
    toPrice.value = null;
  }

  function submitToFinalArray() {
    submittedArray.value = [];
    plantationFilter.value.map((value) => {
      if (value.checked) {
        submittedArray.value.push({ type: 'plantation', text: value.text, value: value.text });
      }
    });
    typeFilter.value.map((value) => {
      if (value.checked) {
        submittedArray.value.push({ type: 'type', text: value.text, value: value.text });
      }
    });
    nameFilter.value.map((value) => {
      if (value.checked) {
        submittedArray.value.push({ type: 'name', text: value.text, value: value.text });
      }
    });
    lengthFilter.value.map((value) => {
      if (value.checked) {
        submittedArray.value.push({ type: 'length', text: `${value.text} см`, value: value.text });
      }
    });
    if (fromPrice.value) {
      submittedArray.value.push({
        type: 'fromPrice',
        text: `От ${fromPrice.value} ₽ за стебель`,
        value: fromPrice.value,
      });
    }
    if (toPrice.value) {
      submittedArray.value.push({
        type: 'toPrice',
        text: `До ${toPrice.value} ₽ за стебель`,
        value: toPrice.value,
      });
    }
  }

  function removeFilter(item: any) {
    switch (item.type) {
      case 'plantation':
        plantationFilter.value = plantationFilter.value.map((obj: any) => {
          if (obj.text === item.value) {
            return { ...obj, checked: false };
          }
          return obj;
        });
        break;
      case 'type':
        typeFilter.value = typeFilter.value.map((obj: any) => {
          if (obj.text === item.value) {
            return { ...obj, checked: false };
          }
          return obj;
        });
        break;
      case 'name':
        nameFilter.value = nameFilter.value.map((obj: any) => {
          if (obj.text === item.value) {
            return { ...obj, checked: false };
          }
          return obj;
        });
        break;
      case 'length':
        lengthFilter.value = lengthFilter.value.map((obj: any) => {
          if (obj.text === item.value) {
            return { ...obj, checked: false };
          }
          return obj;
        });
        break;
      case 'fromPrice':
        fromPrice.value = null;
        break;
      case 'toPrice':
        toPrice.value = null;
        break;
    }
  }

  return {
    submittedArray,
    plantationFilter,
    typeFilter,
    nameFilter,
    lengthFilter,
    fromPrice,
    toPrice,
    dateFilter,
    dates,
    typeFilterTranslateDictionaries,
    fetchFilters,
    setFilters,
    resetPlantationFilter,
    resetTypeFilter,
    resetNameFilter,
    resetAll,
    submitToFinalArray,
    removeFilter,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAssortmentStore, import.meta.hot));
}
