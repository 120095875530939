import componentsRu from './components/ru';
import dictionariesRu from './dictionaries/ru';

import authRu from './pages/auth/ru';
import cartRu from './pages/cart/ru';
import catalogRu from './pages/catalog/ru';
import errorRu from './pages/error/ru';
import confirmRu from './pages/confirm/ru';
import historyRu from './pages/history/ru';
import operationsHistoryRu from './pages/operations-history/ru';
import orderRu from './pages/order/ru';
import profileRu from './pages/profile/ru';


export default {
  components: componentsRu,
  dictionaries: dictionariesRu,
  pages: {
    auth: authRu,
    cart: cartRu,
    catalog: catalogRu,
    error: errorRu,
    confirm: confirmRu,
    history: historyRu,
    operationsHistory: operationsHistoryRu,
    order: orderRu,
    profile: profileRu,
  },
};
