export default {
  title: 'Order history',
  search: 'Searching...',
  order: 'Order',
  deleteOrder: 'Delete order',
  total: 'Total:',
  flowers: 'Flowers',
  cancel: 'Cancel',
  apply: 'Apply',
  edit: 'Edit',
  useHead: {
    historyTitle: 'Order history',
    orderTitle: 'Order',
  },
  history: {
    height: 'Height:',
    cm: 'cm',
    quantity: 'Quantity:',
    pc: 'piece',
    totalCost: 'Total cost:',
    deliveryFrom: 'Delivery from:',
  },
  edited: {
    height: 'Height:',
    cm: 'cm',
    price: 'Price:',
    flower: 'flower',
    forAll: 'for everything',
    flowers: 'flowers',
    inStock: 'In stock:',
  },
  notification: {
    emptyOrder: 'Cannot save empty order',
    emptyPos: 'Delete positions that have a zero balance',
    error: 'An unexpected error occurred',
    confirmDel: 'Are you sure you want to delete this order',
  },
}