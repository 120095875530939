export default {
  useHead: {
    transfers: 'Переводы', // order
    transferBetweenAccounts: 'Перевод между счетами', // myself
  },
  transfers: 'Переводы', // order
  recipient: 'Получатель',
  sum: 'Сумма',
  review: 'Обзор',
  notEnoughFunds: 'На Вашем счете недостаточно средств!',
  betweenAccounts: 'Между счетами',
  back: 'Назад',
  ordering: 'Оформление',
  confirm: 'Подтвердить',
  continue: 'Продолжить',
}
