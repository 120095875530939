export default {
  catalog: 'Catalog',
  assortment: 'Assortment',
  transactions: 'Transactions',
  operationsHistory: 'Operations history',
  transfers: 'Transfers',
  exchange: 'Exchange',
  auction: 'Auction',
  information: 'Information',
  verification: 'Verification',
  replenishments: 'Replenishments',
  topUpBalance: 'Top up balance',
  betweenYourAccounts: ' Between your accounts',
  nameless: 'Nameless',
  profile: 'Profile',
  historyOfOrders: 'History of orders',
  logout: 'Logout',
}
