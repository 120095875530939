import authRu from './auth/ru';
import catalogRu from './catalog/ru';
import assortmentRu from './assortment/ru';
import commonRu from './common/ru';
import recipientRu from './recipient/ru';

export default {
  auth: authRu,
  catalog: catalogRu,
  common: commonRu,
  recipient: recipientRu,
  assortment: assortmentRu,
}