import { User } from '../user';
import type { Timestamp } from '@/interfaces/common';

export interface Account extends Timestamp {
  amount: number;
  type: AccountType;
  user: User;
}

export interface AccountRate {
  value: {
    sended: number;
    received: number;
  };
  course: {
    current: number;
    garantex: number;
  };
}

export enum AccountType {
  RUBLE = 'RUBLE',
  DOLLAR = 'DOLLAR',
  EURO = 'EURO',
}

export enum AccountCurrentType {
  RUBLE = 'RUB',
  DOLLAR = 'USD',
  EURO = 'EUR',
}

export enum AccountTypeName {
  RUBLE = 'Рубль',
  DOLLAR = 'Доллар',
  EURO = 'Евро',
}

export enum AccountTypeSymbol {
  RUBLE = '₽',
  DOLLAR = '$',
  EURO = '€',
}

export const AccountTypeList = [
  { _id: AccountType.DOLLAR, name: AccountTypeName.DOLLAR },
  { _id: AccountType.EURO, name: AccountTypeName.EURO },
  { _id: AccountType.RUBLE, name: AccountTypeName.RUBLE },
];
