import { Account, AccountCurrentType, AccountModel } from '@/interfaces';

import iconCurrencyEuro from '@/assets/icons/currency/euro.svg';
import iconCurrencyRuble from '@/assets/icons/currency/ruble.svg';
import iconCurrencyDollar from '@/assets/icons/currency/dollar.svg';

const AccountTypeIcon = {
  EURO: iconCurrencyEuro,
  RUBLE: iconCurrencyRuble,
  DOLLAR: iconCurrencyDollar,
};

export const useAccountModel = (item: Account): AccountModel => {
  const model = reactive({
    _id: '',
    amount: 0,
    type: '',
    symbol: '',
    currentType: '',
  });

  (function () {
    model._id = item._id;
    model.amount = item.amount;
    model.type = item.type;
    model.currentType = AccountCurrentType[item.type];
    model.symbol = AccountTypeIcon[item.type];
  })();

  const getters = {
    getId: model._id,
    getAmount: computed(() => model.amount),
    getType: model.type,
    getCurrentType: model.currentType,
    getSymbol: model.symbol,
  };

  const actions = {
    updateAmount(value: number) {
      model.amount = value;
    },
  };

  return {
    ...getters,
    ...actions,
  };
};
