import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', () => {
  const token = ref<string>('');
  // const refresh = ref<string>('');

  const getters = {
    getToken: computed(() => token.value),
    // getRefresh: computed(() => refresh.value),
  };

  const setToken = (t: string) => {
    token.value = t;
    // refresh.value = r;
  };

  return {
    token,
    // refresh,
    ...getters,

    setToken,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
