import { Plantation, PlantationModel, Recipient } from '@/interfaces';

export const usePlantationModel = (item: Plantation): PlantationModel => {
  const model = {
    _id: '',
    name: '',
    recipients: [] as Recipient[],
  };

  (function () {
    model._id = item._id;
    model.name = item.name;
    model.recipients = item.recipients || [];
  })();

  const getters = {
    getId: model._id,
    getName: model.name,
    getRecipients: model.recipients,
  };

  return {
    ...getters,
  };
};
