export default {
  recipient: 'Recipient',
  selectRecipient: 'Select recipient',
  plantation: 'Plantation',
  selectPlantation: 'Select plantation',
  enterPlantationName: 'Enter the name of the plantation',
  accounts: '# Accounts',
  enterAccountNumber: 'Enter account number',
  city: 'City',
  enterCity: 'Enter a city',
  postalCode: 'Postal code',
  enterPostcode: 'Enter postcode',
  bankCountry: 'Bank Country',
  selectBankCountry: 'Select bank country',
  enterRoutingNumber: 'Enter Routing Number',
  SWIFTBankCode: 'SWIFT bank code',
  enterCode: 'Enter code',
  selectAccount: 'Select account',
  debitFromAccount: 'Debit from account',
  amount: 'Amount',
  enterAmount: 'Enter amount',
}
