import { defineStore } from 'pinia';
import { Notification } from '@/interfaces';

export const useNotificationStore = defineStore('notification', {
  state: () => {
    return {
      notification: null as Notification | null,
    };
  },
  getters: {
    getNotification: (state) => state.notification,
  },
  actions: {
    create(notification: Notification) {
      this.$patch({ notification });
    },
    destroy() {
      this.$patch({ notification: null });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}
